import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Modal from 'react-modal';
import queryString from 'query-string';
import {useQuery} from "react-query";
import { repositoryConfigs } from '../../utils/prismic-previews'
import { Layout } from "../../components/global/layout"
import { Masthead } from "../../components/layout/masthead"
import AdvisorRegisterPersonalDetails from "../../components/forms/advisor_register_personal_details";
import {SliceZones} from "../../components/slices/slice-zones";
import {logResponse} from "../../api/sage";



const RegisterPersonalDetails = ({ data, pageContext }) => {
    const [showWorkAckModal, setshowWorkAckModal] = useState(false);

    useQuery(
        'log_expert_response',
        () => {
            const token = queryString.parse(window.location.search).token;
            const response = queryString.parse(window.location.search).response || '';

            if (!token) {
                return;
            }

            setshowWorkAckModal(true);
            return logResponse(token, response);
        },
        {
            retries: 3,
        }
    );

        // Check payment token
    useEffect(() => {
        const queryStringObject = queryString.parse(window.location.search);
        if (!('tokenr' in queryStringObject)) {
            return;
        }

        setshowWorkAckModal(true);


        fetch(`https://europe-west2-outsmart-production.cloudfunctions.net/ack_work`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    token: queryStringObject.tokenr,
                    response: queryStringObject.response || '',
                }),
            })
            .catch((error) => {

            })
        }, []);



    if (!data)
      return null

    const document = data.prismicAdvisorsRegisterPersonalDetails

    return (
        <Layout pageTitle={document.data.title.text}
                metaTitle={document.data.meta_title.text}
                metaDescription={document.data.meta_description.text || document.data.summary.text}
                shareImage={document.data.share_image.url}>
            <Masthead subtitle={document.data.subtitle.text} title={document.data.title.text} summary={document.data.summary.text} />
            <AdvisorRegisterPersonalDetails image={document.data.form_image.url} />
            <SliceZones slices={document.data.body} />

            <Modal
                isOpen={showWorkAckModal}
                onRequestClose={() => setshowWorkAckModal(false)}
                className="ack-work-modal"
            >
              <button type="button" className="btn-close" onClick={() => setshowWorkAckModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>

                <h2>Thank you for letting us know</h2>

                <p>
                    We have noted your response.
                </p>
            </Modal>
        </Layout>
    )
}

export const query = graphql`
  query AdvisorsRegisterPersonalDetailsQuery {
    prismicAdvisorsRegisterPersonalDetails {
      _previewable
      data {
        title {
          text
        }
        summary {
          text
        }
        subtitle {
          text
        }
        form_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        body {
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
              display_tag
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                      industry
                      service
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicAdvisorsRegisterPersonalDetailsDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }  
`

export default withPrismicPreview(RegisterPersonalDetails, repositoryConfigs)

