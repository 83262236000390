import React, { useState, useRef } from "react"
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import * as _ from "lodash";
import Select from "../fields/select";
import {COUNTRY_CHOICES, TITLE_CHOICES} from "../../constants";
import ProgressBar from "../progress-bar";
import {PERSONAL_DETAILS_STEP} from "../progress-bar/constants";
import AdvisorsTCs from "../modals/AdvisorsTCs";
import PrivacyPolicy from "../modals/PrivacyPolicy";


export const AdvisorRegisterPersonalDetails = ({ image }) => {
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false,
        error: '',
      });

    const [selectedFile, setSelectedFile] = useState('');

    const [showTCsModal, setShowTCsModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const formRef = useRef(null)

    const onSubmit = (data) => {
        // Create form data object
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            if (data[key] instanceof FileList) {
                formData.append(key, data[key][0]);
                return;
            }
            formData.append(key, data[key]);
        });

        fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/expert/register/personal_details/`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `apikey ${process.env.GATSBY_ASSOCIATES_API_USER}:${process.env.GATSBY_ASSOCIATES_API_KEY}`,
            }})
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }

                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            })
            .then(response => {
                formRef.current.scrollIntoView();
                setState({
                    isSubmitted: true,
                });
            })
            .catch(async (err) => {
                if (err.response) {

                    const responseErr = await err.response.json();

                    setState({
                        isError: true,
                        error: responseErr['error_message']
                    });

                    if (responseErr['expert']) {
                        let focus = true;
                        for(let fieldName in responseErr['expert']) {
                            setError(fieldName, {type: '', message: responseErr['expert'][fieldName][0]},
                                {shouldFocus: focus});
                            focus = false;
                        }
                    }
                } else {
                    formRef.current.scrollIntoView();
                    setState({
                        isError: true,
                        error: 'Server error. Please try again later.'
                    })
                }
            });
    }

    const onCvUploadChange = (event) => {
        let { value } = event.target;

        if (value) {
            const startIndex =
                value.indexOf('\\') >= 0
                    ? value.lastIndexOf('\\')
                    : value.lastIndexOf('/');

            const filename = value.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                value = filename.substring(1);
            }

            setSelectedFile(value);
        } else {
            setSelectedFile('');
        }
  }

    return (
        <>
            <div className="c-register" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-register__media-container">
                            <img src={image !== null ? image : "/images/test/contact.png"} alt="" className="c-register__media" loading="lazy" />
                        </div>
                        <div className="c-register__form">
                            <ProgressBar currentStep={PERSONAL_DETAILS_STEP} />
                            <div className="f-section">
                                <h3>Personal Details</h3>
                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {state.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{state.error}</p>
                                          </div>
                                      </div>
                                    )}

                                    {state.isSubmitted && (
                                        <p>
                                            Thank you for joining Outsmart Insight Advisors. On
                                            completing your first client engagement you will be
                                            contacted by our team with details of a personalised
                                            payment link to input your payment details. If you have
                                            any questions in the meantime please contact us at
                                            advisors@outsmartinsight.com
                                        </p>
                                    )}

                                    {!state.isSubmitted && (
                                        <>
                                            <div className="row">
                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.first_name})}>
                                                        <label htmlFor="first_name">First Name</label>
                                                        <input
                                                            name="first_name" className="f-control" type="text"
                                                            placeholder="Please enter your first name(s)"
                                                            {...register("first_name", { required: "This field is required" })}
                                                        />
                                                        {errors.first_name && <span className="f-error">{errors.first_name.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.last_name})}>
                                                        <label htmlFor="last_name">Surname</label>
                                                        <input
                                                            name="last_name" className="f-control" type="text"
                                                            placeholder="Please enter your surname or family name"
                                                            {...register("last_name", { required: "This field is required" })}
                                                        />
                                                        {errors.last_name && <span className="f-error">{errors.last_name.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.email})}>
                                                        <label htmlFor="email">Email</label>
                                                        <input
                                                            name="email" className="f-control" type="text"
                                                            placeholder="Email Address"
                                                            {...register("email", { required: "This field is required", pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Enter a valid email address."}})}
                                                        />
                                                        {errors.email && <span className="f-error">{errors.email.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.phone})}>
                                                        <label htmlFor="phone">Phone</label>
                                                        <input
                                                            name="phone" className="f-control" type="text"
                                                            placeholder="Please enter your phone number"
                                                            {...register("phone", { required: "This field is required" })}
                                                        />
                                                        {errors.phone && <span className="f-error">{errors.phone.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.title})}>
                                                        <label htmlFor="title">Title</label>

                                                        <Controller
                                                            control={control}
                                                            name="title"
                                                            rules={{ required: 'This field is required' }}
                                                            render={({ field }) => <Select {...field} data={TITLE_CHOICES} />}
                                                        />

                                                        {errors.title && <span className="f-error">{errors.title.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.nationality})}>
                                                        <label htmlFor="nationality">Nationality</label>

                                                        <Controller
                                                            control={control}
                                                            name="nationality"
                                                            rules={{ required: 'This field is required' }}
                                                            render={({ field }) => <Select {...field} data={COUNTRY_CHOICES} />}
                                                        />

                                                        {errors.nationality && <span className="f-error">{errors.nationality.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <p>
                                                        Please provide a link to your LinkedIn profile page and
                                                        upload a resume/CV. Alternatively you may do this later if your CV is
                                                        not readily available.
                                                    </p>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.linkedin})}>
                                                        <label htmlFor="linkedin">Linkedin</label>
                                                        <input
                                                            name="linkedin" className="f-control" type="text"
                                                            placeholder="Please enter url to your Linkedin profile"
                                                            {...register("linkedin")}
                                                        />
                                                        {errors.linkedin && <span className="f-error">{errors.linkedin.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.cv_upload})}>
                                                        <label htmlFor={"cv_upload"}>Attach your CV</label>

                                                        <div>
                                                            <input
                                                              name="cv_upload"
                                                              id="cv_upload"
                                                              type="file"
                                                              className="inputfile inputfile-1"
                                                              {...register("cv_upload", {onChange: onCvUploadChange})}
                                                            />
                                                            <label htmlFor="cv_upload">
                                                              {_.isEmpty(selectedFile) ? (
                                                                <span>Select a file to upload</span>
                                                              ) : (
                                                                <span>{selectedFile}</span>
                                                              )}

                                                              <strong>BROWSE</strong>
                                                            </label>
                                                        </div>
                                                        {errors.cv_upload
                                                            ? (<span className="f-error">{errors.cv_upload.message}</span>)
                                                            : (<span className="f-info">Please make sure your file is in one of these formats: PDF, JPG, PNG</span>)
                                                        }
                                                    </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem checkbox",{"f-field__elem--has-error": errors.agree_terms}, "tcs")}>
                                                        <input
                                                            className="f-control"
                                                            type="checkbox"
                                                            {...register("agree_terms", {required: "This field is required"})}
                                                        />
                                                        <label>
                                                            I agree to{' '}
                                                            <a
                                                                href="#showAdvisorsTCModal"
                                                                role="button"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setShowTCsModal(true);
                                                                }}
                                                                className="policy"
                                                            >
                                                                Advisors Terms & Conditions
                                                            </a>{' '}
                                                            and{' '}
                                                            <a
                                                                href="#showPrivacyPolicyModal"
                                                                role="button"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setShowPrivacyModal(true);
                                                                }}
                                                                className="policy"
                                                            >
                                                                Privacy Policy
                                                            </a>
                                                        </label>
                                                    {errors.agree_terms && <span className="f-error">{errors.agree_terms.message}</span>}
                                                </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <button
                                                        type="submit"
                                                        className="c-btn c-btn--large c-btn--primary"
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <AdvisorsTCs showModal={showTCsModal} onHide={() => setShowTCsModal(false)} />
            <PrivacyPolicy showModal={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} />
        </>
    )
}

export default AdvisorRegisterPersonalDetails;